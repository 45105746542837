
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@/styles/variable.scss';

.indexpage {
    :global {
        background-color: #fff;
        .indexbannerPc {
            .index-card {
                height: 800px;
                .msg-title {
                    html[site='jp'] & {
                        @include ef-font(Medium);
                    }
                    html[site='cn'] {
                        white-space: nowrap;
                    }
                }
                &.index-card-ces {
                    .msg-box {
                        top: 106px;
                    }
                    .msg-title {
                        font-size: 48px;
                        line-height: 56px;
                    }
                    .msg-desc1 {
                        font-size: 20px;
                        font-family: var(--font-family-Regular);
                        line-height: 28px;
                        color: #fff;
                        margin-top: 4px;
                    }
                    .desc-box {
                        margin-top: 40px;
                        color: #dfe21e;
                    }
                    .msg-desc2 {
                        font-family: var(--font-family-SemiBold);
                        font-size: 20px;
                        line-height: 28px;
                    }
                    .msg-desc3 {
                        font-family: var(--font-family-Medium);
                        font-size: 16px;
                        line-height: 24px;
                        color: #dfe21e;
                        margin-top: 4px;
                    }
                }
                &.black {
                    .msg-box {
                        .msg-title,
                        .msg-desc {
                            color: #03060b;
                        }
                    }
                }
            }
            .swiper {
                .swiper-pagination-bullet {
                    background: rgb(255, 255, 255);
                    opacity: 0.3;
                    width: 10px;
                    height: 10px;
                    margin: 0px;
                    margin-right: 10px !important;
                }

                .swiper-pagination-bullet-active {
                    background: #fff;
                    opacity: 1;
                }

                .swiper-pagination {
                    bottom: 28px;
                }
                .swiper-button-prev,
                .swiper-button-next {
                    background: rgba(0, 0, 0, 0.1);
                    width: 44px;
                    border-radius: 22px;
                    color: #fff;
                    transition: all 0.4s;
                    &:after {
                        font-size: 16px;
                    }
                    &:hover {
                        background: rgba(0, 0, 0, 0.2);
                        color: #fff;
                    }
                }
                .swiper-button-prev {
                    left: 46px;
                }
                .swiper-button-next {
                    right: 46px;
                }

                html[site='sa'] & {
                    .swiper-button-prev {
                        right: 46px;
                        left: auto;
                    }
                    .swiper-button-next {
                        left: 46px;
                        right: auto;
                    }
                }
            }
        }
        .index-card {
            position: relative;
            height: 520px;
            cursor: pointer;
            &.index-card-1 {
                height: 720px;
            }
            .img-box {
                position: relative;
                z-index: 1;
                height: 100%;
            }
            .msg-box {
                position: absolute;
                top: 64px;
                left: 0;
                z-index: 2;
                width: 100%;
                text-align: center;
                color: #fff;
                &.ecocredits_banner {
                    top: 10px;
                    .msg-desc {
                        width: 620px;
                        position: relative;
                        font-family: var(--font-family-Regular);
                        color: #fff;
                        font-size: 19px;
                        line-height: 27px;
                        text-align: center;
                        margin: auto;
                        padding-top: 20px;
                        white-space: pre-line;
                    }
                    .thir-title {
                        width: 600px;
                        position: relative;
                        font-family: var(--font-family-Light);
                        color: #c99162;
                        line-height: 27px;
                        font-size: 19px;
                        text-align: center;
                        margin: auto;
                        padding-top: 10px;
                    }
                }
                .new {
                    font-size: 17px;
                    color: #fa4500;
                    padding-top: 12px;
                    padding-bottom: 8px;
                    font-family: var(--font-family-Medium);
                    font-weight: 600;
                    min-height: 40px;

                    html[site='cn'] & {
                        font-family: var(--font-family-Regular);
                        font-weight: 400;
                    }
                }
                .sub-title {
                    font-family: Manrope-Regular, EF-Font-Regular;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 21px;
                    margin-bottom: 4px;
                    color: #fff;
                }
                .msg-title {
                    font-family: var(--font-family-Bold);
                    font-weight: 600;
                    line-height: 1.14;
                    font-size: 48px;
                    white-space: pre-line;
                    color: #fff;
                    html[site='jp'] & {
                        @include ef-font(Medium);
                    }
                }
                .msg-desc {
                    font-weight: 500;
                    font-family: var(--font-family-Regular);
                    line-height: 1.36;
                    margin-top: 4px;
                    font-size: 20px;
                    html[site='cn'] & {
                        white-space: pre-line;
                        font-size: 22px;
                        line-height: 40px;
                    }
                }
                .msg-link {
                    margin-top: 24px;
                    .msg-linkitem {
                        display: inline-block;
                        width: auto;
                        height: 32px;
                        line-height: 20px;
                        padding: 6px 0;
                        margin: 0 8px;
                        vertical-align: top;
                        white-space: nowrap;
                        padding-right: 15px;
                        font-weight: 400;
                        font-family: var(--font-family-Regular);
                        color: #2673ff;
                        font-size: 17px;
                        background-position: right center;
                        background-size: 8px 12px;
                        background-repeat: no-repeat;
                        background-position-y: 10px;
                    }
                    .ef-icon {
                        margin-left: 12px;
                    }
                }
                .energy_saving_link {
                    margin-top: 200px;
                }
                &.solar_generator {
                    .sub-title {
                        font-family: Manrope-Regular, EF-Font-Regular;
                        font-size: 37px;
                        line-height: 50px;
                        color: #2c2f38;
                    }
                    .msg-title {
                        font-family: Manrope-Bold, EF-Font-Bold;
                        font-size: 53px;
                        line-height: 67px;
                        color: #383c45;
                    }
                    .msg-desc {
                        font-family: Manrope-Regular, Ef-Font-Regular;
                        font-weight: 400;
                        font-size: 20px;
                        margin-top: 7px;
                        color: #2c2f38;
                    }
                }
                &.river2_tanaka_jp {
                    .sub-title {
                        color: #fa4500;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    &:global(.indexpageH5) {
        :global {
            .indexbannerH5 {
                .index-card {
                    height: 5rem;
                    &.index-card-ces {
                        .msg-box {
                            top: 0;
                            height: 100%;
                            padding-top: 0.76rem;
                        }
                        .msg-title {
                            font-size: 0.28rem;
                            line-height: 0.32rem;
                            width: 3rem;
                            html[site='jp'] & {
                                font-weight: 600;
                            }
                            html[site='cn'] & {
                                white-space: pre-line;
                            }
                        }
                        .msg-desc1 {
                            width: 2.6rem;
                            font-size: 0.14rem;
                            font-family: var(--font-family-Regular);
                            line-height: 0.2rem;
                            color: #fff;
                            margin: 0.09rem auto 0;
                        }
                        .desc-box {
                            position: absolute;
                            bottom: 0.42rem;
                            left: 0;
                            width: 100%;
                            color: #dfe21e;
                        }
                        .msg-desc2 {
                            font-family: var(--font-family-SemiBold);
                            font-size: 0.16rem;
                            line-height: 0.2rem;
                        }
                        .msg-desc3 {
                            font-family: var(--font-family-Medium);
                            font-size: 0.12rem;
                            line-height: 0.16rem;
                            width: 3.2rem;
                            margin: 0.04rem auto 0;
                        }
                    }
                    &.black {
                        .msg-box {
                            .msg-title,
                            .msg-desc {
                                color: #03060b;
                            }
                        }
                    }
                }
                .swiper {
                    .swiper-pagination-bullet {
                        width: 0.14rem;
                        height: 0.02rem;
                        border-radius: 0.02rem;
                        background: rgba(255, 255, 255, 0.4);
                        margin: 0 0.09rem 0 0 !important;
                    }

                    .swiper-pagination-bullet-active {
                        background: rgba(255, 255, 255, 1);
                    }

                    .swiper-pagination-horizontal {
                        bottom: 0.24rem;
                    }
                }
            }
            .index-card {
                height: 4rem;
                .msg-box {
                    top: 0.32rem;
                    width: 100%;
                    margin: 0 auto;
                    &.ecocredits_banner {
                        top: 0.05rem;
                        .msg-box-in {
                            min-width: 3.5rem;
                        }
                        .msg-title {
                            html[site='jp'] & {
                                font-size: 0.28rem;
                            }
                        }
                        .msg-desc {
                            width: 3.05rem;
                            font-size: 16px !important;
                            font-family: var(--font-family-SemiBold);
                            text-align: center;
                            color: #fff;
                            line-height: 20px;
                            white-space: pre-line;
                            margin: auto;
                            padding-top: 0.1rem;
                            html[site='jp'] & {
                                font-size: 14px !important;
                                margin-top: 6px;
                                line-height: 17px;
                                font-family: var(--font-family-Medium);
                            }
                        }
                        .thir-title {
                            width: 3.05rem;
                            font-size: 16px;
                            font-family: var(--font-family-Regular);
                            text-align: center;
                            color: #c99162;
                            line-height: 20px;
                            margin: 0 auto 0;
                            html[site='jp'] & {
                                margin-top: 6px;
                            }
                        }
                        .msg-link {
                            margin-top: 0;
                        }
                    }
                    .new {
                        line-height: 0.16rem;
                        padding-bottom: 0.08rem;
                        padding-top: 0;
                        font-family: var(--font-family-Medium);
                        font-weight: 500;
                        font-size: 0.16rem;
                        min-height: 0.2rem;
                    }
                    .msg-box-in {
                        max-width: 3.1rem;
                        margin: 0 auto;
                    }
                    .msg-title {
                        font-size: 0.32rem;
                        line-height: 0.32rem;
                        font-family: var(--font-family-Bold);
                        font-weight: 500;
                        html[site='jp'] & {
                            font-family: var(--font-family-SemiBold);
                        }
                        html[site='sa'] & {
                            font-weight: 600;
                        }
                        html[site='cn'] & {
                            font-weight: normal;
                        }
                    }
                    .msg-desc {
                        margin-top: 0.14rem;
                        line-height: 0.2rem;
                        font-size: 0.18rem;
                        html[site='cn'] & {
                            white-space: pre-line;
                            line-height: 0.26rem;
                            &.small {
                                line-height: 0.3rem;
                            }
                        }
                        html[site='jp'] & {
                            white-space: pre-line;
                            font-size: 0.2rem;
                            line-height: 0.24rem;
                            &.small {
                                font-size: 0.18rem;
                                line-height: 0.3rem;
                            }
                            &.vice-desc {
                                font-size: 0.16rem;
                                line-height: 0.22rem;
                            }
                        }
                        &.small {
                            font-size: 0.18rem;
                            line-height: 0.3rem;
                        }
                    }
                    .msg-link {
                        margin-top: 0.1rem;
                        .msg-linkitem {
                            padding-right: 15px;
                            height: 0.26rem;
                            line-height: 0.2rem;
                            padding: 0.03rem 0.15rem;
                            background-size: 0.1rem 0.1rem;
                            background-position-y: 0.09rem;
                            font-size: 0.16rem;
                        }
                    }
                    .energy_saving_link {
                        margin-top: 1.2rem;
                    }
                    &.solar_generator {
                        .msg-box-in {
                            max-width: 100%;
                        }
                        .sub-title {
                            font-size: 0.16rem;
                            line-height: 0.24rem;
                            color: #212328;
                        }
                        .msg-title {
                            font-family: Manrope-Bold, EF-Font-Bold;
                            margin: 0.05rem auto 0;
                            font-size: 0.2rem;
                            line-height: 0.28rem;
                            color: #000;
                        }
                        .msg-desc {
                            margin: 0.07rem auto 0;
                            font-family: Manrope-Regular, Ef-Font-Regular;
                            font-weight: 400;
                            font-size: 0.12rem;
                            line-height: 0.2rem;
                            white-space: pre-line;
                            color: #212328;
                            html[site='jp'] & {
                                font-size: 0.12rem;
                                line-height: 0.2rem;
                            }
                        }
                    }
                    &.river2_tanaka_jp {
                        .sub-title {
                            font-size: 0.16rem;
                            line-height: 0.2rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
