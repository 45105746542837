
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.ef-emarsys-email {
    position: relative;
    width: 100%;
    .ef-emarsys-email-in {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        line-height: 48px;
        color: #000;
        border-radius: 4px;
        overflow: hidden;
    }
    .ef-emarsys-email-l {
        display: flex;
        align-items: center;
        padding-left: 24px;
    }
    .ef-emarsys-email-r {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 40px;
        text-align: center;
        background-color: #3f68e0;
        color: #fff;
        cursor: pointer;
        font-family: var(--ff-woff2-B);
        border-radius: 0 4px 4px 0;
        user-select: none;
        font-size: 14px;
        &:hover,
        &:active {
            background-color: #3f4ce0;
        }
        html[site=br] & {
            padding: 0 12px;
        }
    }
    .disabled-email-btn {
        background-color: #ccc;
    }
    .ef-emarsys-email-text {
        font-family: var(--ff-woff2-M);
        font-size: 14px;
        line-height: 1.2;
        white-space: pre-line;
    }
    .ef-emarsys-email-c {
        flex: 1;
        height: 100%;
    }
    .ef-emarsys-email-input {
        display: block;
        width: 100%;
        outline: none;
        border: none;
        height: 100%;
        font-size: 16px;
        font-family: var(--ff-woff2-M);
        background-color: #fff;
        color: #707070;
        padding-left: 12px;
        border: 2px solid #3f68e0;
        border-radius: 4px 0 0 4px;
        &::placeholder {
            color: #afafaf;
        }
    }
    .disabled-email-input {
        border: 2px solid #ccc;
    }
    .ef-emarsys-email-errors {
        min-height: 26px;
        text-align: left;
        line-height: 24px;
        color: #f8200c;
        margin-top: 10px;
    }
    .ef-emarsys-email-errors:empty {
        display: none;
    }
    .ef-emarsys-email-terms {
        margin-top: 10px;
    }
    .ef-emarsys-email-checkbox {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin-right: 10px;
        vertical-align: top;
        overflow: hidden;
        cursor: pointer;
    }
    .ef-emarsys-email-terms-text {
        font-family: var(--ff-woff2-M);
        font-size: 12px;
        line-height: 1.2;
        color: #707070;
        a {
            color: #000;
            text-decoration: underline;
        }
        &.customize{
            a{
                color: inherit;
            }
        }
    }
    .ef-emarsys-email-terms{
        &.config{
            position: relative;
            margin: 12px auto 0;
            width: max-content;
            max-width: 100%;
            .cover{
                position: absolute;
                left: 0;
                top: 0;
                z-index: 10;
                width: 16px;
                height: 16px;
                border-radius: 2.4px;
                overflow: hidden;
                pointer-events: none;
                &.check{
                    &::after{
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        margin-top: -2px;
                        width: 12px;
                        height: 6px;
                        border: 2.4px solid #fff;
                        border-top: 0;
                        border-right: 0;
                    }
                }
            }
            .ef-emarsys-email-checkbox{
                opacity: 0;
            }
        }
    }
    @include devices(pad) {
        .ef-emarsys-email-l {
            padding-left: 12px;
        }
        .ef-emarsys-email-input {
            border-width: 1px;
        }
        .terms {
            margin-top: 6px;
            .checkbox {
                margin-right: 6px;
            }
            .terms-text {
                .link {
                    text-decoration: underline;
                }
            }
        }
    }
    @include devices(h5) {
        .ef-emarsys-email-in {
            flex-direction: column;
            gap: 14px;
            height: auto;
            border-radius: 0;
        }
        .ef-emarsys-email-c {
            width: 100%;
            height: 47px;
            flex: none;
        }
        .ef-emarsys-email-r {
            width: 100%;
            height: 47px;
            border-radius: 4px;
            padding: 0 12px;
        }
        .ef-emarsys-email-input {
            font-size: 14px;
            font-family: var(--ff-woff2-M);
            border-radius: 4px;
        }
        .terms {
            margin-top: 12px;
        }
    }
}

// 选择列表
.ef-emarsys-email-product-list {
    display: flex;
    gap: 8px;
    margin-bottom: 40px;
    font-family: var(--ff-woff2-M);
    font-size: 14px;
    line-height: 1.2;
    .ef-emarsys-email-product-item {
        display: flex;
        gap: 6px;
        align-items: center;
        cursor: pointer;
    }
    .ef-emarsys-email-product-item-input {
        display: flex;
        width: 16px;
        height: 16px;
        &:checked {
            accent-color: #9ACCF8;
        }
    }
    .ef-emarsys-email-product-item-label {
        cursor: pointer;
    }
    @include devices(desktop) {
        margin-bottom: 30px;
    }
    @include devices(pad) {
        margin-bottom: 24px;
        justify-content: center;
    }
    @include devices(h5) {
        justify-content: flex-start;
        .ef-emarsys-email-product-item {
            flex: 1;
        }
    }
}

.ef-emarsys-email.ef-emarsys-email-type-success {
    .ef-emarsys-email-errors {
        color: #3f68e0;
    }
}

// 黑色主题
.ef-emarsys-email-theme-black {
    .ef-emarsys-email-input {
        border-color: #000;
    }
    .ef-emarsys-email-r {
        background-color: #000;
    }
    .ef-emarsys-email-checkbox:checked {
        accent-color: #000;
    }
    .ef-emarsys-email-terms .ef-emarsys-email-terms-text {
        color: #000;
        a {
            color: #000;
        }
    }
}

// 白色主题
.ef-emarsys-email-theme-white {
    .ef-emarsys-email-input {
        border-color: #000;
    }
    .ef-emarsys-email-r {
        background-color: #000;
    }
    .ef-emarsys-email-checkbox:checked {
        accent-color: #fff;
    }
    .ef-emarsys-email-terms .ef-emarsys-email-terms-text {
        color: #fff;
        a {
            color: #fff;
        }
    }
}
