
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.topIcon {
    :global {
        .win-icon {
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 205px;
            right: 20px;
            z-index: 99;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
            cursor: pointer;
            &:hover {
                background-color: #f0f0f0;
            }
            html[site='de'] &,
            html[site='fr'] &,
            html[site='es'] &,
            html[site='it'] &,
            html[site='uk'] &,
            html[site='eu'] &,
            html[site='za'] &,
            html[site='jp'] &,
            html[site='au'] & {
                bottom: 275px;
            }
            @include devices(pad) {
                bottom: 180px;
                width: 40px;
                height: 40px;
                svg {
                    scale: 0.85;
                }
                html[site='de'] &,
                html[site='fr'] &,
                html[site='es'] &,
                html[site='it'] &,
                html[site='uk'] &,
                html[site='eu'] &,
                html[site='za'] &,
                html[site='jp'] &,
                html[site='au'] & {
                    bottom: 235px;
                }
            }
        }
    }
}

// 主弹窗
.dialogWin {
    :global {
        display: none;
        position: fixed;
        bottom: 65px;
        z-index: 99999;
        right: 105px;
        width: 375px;
        height: 564px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.15);
        max-height: min(565px, 80vh);
        transition: display 1s;
        margin: 0;
        padding: 0;
        user-select: none;
        .EcoCreditsWin-close {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        .EcoCreditsWin-header {
            position: sticky;
            top: 0;
            height: 52px;
            padding: 16px 20px;
            display: flex;
            align-items: center;
            background-color: #f4f4f2;
            // box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.15);
            z-index: 10;
        }
        .EcoCreditsWin-header-back {
            cursor: pointer;
        }
        .EcoCreditsWin-header-ms {
            display: none;
            flex: 1;
            text-align: center;
            font-family: var(--ff-woff2-B);
            font-size: 18px;
            color: #000;
        }
        .EcoCreditsWin-body {
            display: none;
            padding: 12px 0 0;
        }
        @include devices(h5) {
            bottom: 0;
            right: 0;
            width: 100%;
        }
    }
    &:global(.dialogWinOpen) {
        :global {
            display: block;
        }
    }
    &:global(.is-login) {
        :global {
            .EcoCreditsWin-body-a,
            .EcoCreditsWin-body-b,
            .EcoCreditsWin-body-c,
            .EcoCreditsWin-body-d,
            .EcoCreditsWin-body-e,
            .EcoCreditsWin-body-f,
            .EcoCreditsWin-body-g {
                height: calc(100% - 52px) !important;
                @include devices(h5) {
                    height: calc(100% - 52px) !important;
                }
            }
        }
    }
    &:global(.dialogWin-type-a) {
        :global {
            .EcoCreditsWin-header {
                background-color: #fff;
                box-shadow: none;
            }
            .EcoCreditsWin-body-a {
                display: block;
                height: calc(100% - 180px);
                overflow-y: auto;
                @include devices(h5) {
                    height: calc(100% - 162px);
                }
            }
            .EcoCreditsWin-header-ms-a {
                display: block;
            }
            .EcoCreditsWin-header-back {
                display: none;
            }
        }
    }
    // 打开子弹窗 B
    &:global(.dialogWin-type-b) {
        :global {
            .EcoCreditsWin-body-b {
                display: block;
                height: calc(100% - 180px);
                overflow-y: auto;
                padding-bottom: 12px;
                @include devices(h5) {
                    height: calc(100% - 162px);
                }
            }
            .EcoCreditsWin-header-ms-b {
                display: block;
                html[site='fr'] & {
                    font-size: 16px;
                }
            }
        }
    }
    // 打开子弹窗 C
    &:global(.dialogWin-type-c) {
        :global {
            .EcoCreditsWin-body-c {
                padding-bottom: 12px;
                display: block;
                height: calc(100% - 180px);
                overflow-y: auto;
                @include devices(h5) {
                    height: calc(100% - 162px);
                }
            }
            .EcoCreditsWin-header-ms-c {
                display: block;
            }
        }
    }
    // 打开子弹窗 D
    &:global(.dialogWin-type-d) {
        :global {
            .EcoCreditsWin-body-d {
                display: block;
                height: calc(100% - 180px);
                overflow-y: auto;
                @include devices(h5) {
                    height: calc(100% - 162px);
                }
            }
            .EcoCreditsWin-header-ms-d {
                display: block;
            }
        }
    }
    // 打开子弹窗 E
    &:global(.dialogWin-type-e) {
        :global {
            .EcoCreditsWin-body-e {
                display: block;
                height: calc(100% - 180px) !important;
                overflow-y: auto;
                @include devices(h5) {
                    height: calc(100% - 162px) !important;
                }
            }
            .EcoCreditsWin-header-ms-e {
                display: block;
                html[site='es'] & {
                    font-size: 16px;
                }
            }
        }
    }
    // 打开子弹窗 F
    &:global(.dialogWin-type-f) {
        :global {
            .EcoCreditsWin-body-f {
                display: block;
                height: calc(100% - 180px);
                overflow-y: auto;
                @include devices(h5) {
                    height: calc(100% - 162px);
                }
            }
            .EcoCreditsWin-header-ms-f {
                display: block;
            }
        }
    }
    // 打开子弹窗 E
    &:global(.dialogWin-type-g) {
        :global {
            .EcoCreditsWin-body-g {
                display: block;
                height: calc(100% - 180px);
                overflow-y: auto;
                @include devices(h5) {
                    height: calc(100% - 162px);
                }
            }
            .EcoCreditsWin-header-ms-g {
                display: block;
                font-size: 14px;
            }
        }
    }
}

// 通用样式
.dialogWinCommon {
    :global {
        .EcoCreditsWin-Section {
            border-radius: 4px;
            border: 1px solid #cacac8;
            padding: 20px;
            margin: 0 24px 16px;
        }
        .EcoCreditsWin-item {
            display: flex;
            align-items: center;
            line-height: 20px;
            cursor: pointer;
            border-bottom: 1px solid #cacac8;
            svg {
                margin-left: auto;
            }
        }
        .EcoCreditsWin-item-icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
        .EcoCreditsWin-title {
            font-family: var(--ff-woff2-B);
            font-size: 18px;
            color: #000;
            line-height: 1.3;
        }
        .EcoCreditsWin-desc {
            font-family: var(--ff-woff2-M);
            font-size: 12px;
            color: #62625f;
            line-height: 1.2;
            margin-top: 6px;
            white-space: pre-line;
        }
        .EcoCreditsWin-item {
            padding: 12px 0;
        }
        .EcoCreditsWin-item-info {
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            color: #000;
            html[site='fr'] & {
                font-size: 13px;
            }
        }
        .EcoCreditsWin-link {
            display: flex;
            align-items: center;
            width: max-content;
            gap: 8px;
            margin-top: 20px;
            cursor: pointer;
            font-family: var(--ff-woff2-B);
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

// footer 样式
.FooterA {
    :global {
        padding: 14px 44px 20px;
        text-align: center;
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);
        color: #000;
        .FooterA-title {
            font-family: var(--ff-woff2-B);
            font-size: 16px;
            color: #000;
        }
        .FooterA-btn {
            font-family: var(--ff-woff2-B);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 32px;
            background-color: #000;
            color: #fff;
            border-radius: 4px;
            margin-top: 8px;
        }
        .FooterA-tips {
            font-family: var(--ff-woff2-M);
            font-size: 12px;
            color: #949491;
            margin-top: 4px;
            .editor-link {
                color: #3f68e0;
                text-decoration: underline;
            }
        }
    }
}

// 子弹窗 B
.WinB {
    :global {
        position: relative;
        .WinB-item {
            padding: 20px 0;
            margin: 0 30px;
            position: relative;
            border-bottom: 1px solid #cacac8;
            cursor: pointer;
        }
        .WinB-line1 {
            display: flex;
            gap: 12px;
        }
        .WinB-icon {
            width: 32px;
            height: 32px;
            display: flex;
        }
        .WinB-r {
            flex: 1;
        }
        .WinB-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: var(--ff-woff2-B);
            color: #000;
            font-size: 14px;
            line-height: 1.2;
        }
        .WinB-score {
            font-family: var(--ff-woff2-M);
            color: #3f68e0;
            font-size: 12px;
            line-height: 1.2;
        }
        .WinB-desc {
            display: none;
            font-family: var(--ff-woff2-M);
            color: #949491;
            font-size: 12px;
            line-height: 1.2;
            margin-top: 10px;
        }
        .WinB-item-open {
            .WinB-desc {
                display: block;
            }
            .WinB-line1 .WinB-title svg {
                transform: rotate(90deg);
            }
        }
    }
}

// 子弹窗 C
.WinC {
    :global {
        position: relative;
        .WinC-box-a,
        .WinC-box-b {
            margin: 16px 24px 0;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid #cacac8;
        }
        html[site='jp'] & {
            .WinC-box-b:first-child {
                margin-bottom: 24px;
            }
        }
        .WinC-title {
            font-family: var(--ff-woff2-B);
            font-size: 16px;
            color: #000;
        }
        .WinC-a-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 12px 0;
            margin-top: 12px;
        }
        .WinC-a-score {
            position: relative;
            width: 48px;
            height: 32px;
            margin: 0 auto;
            html[site='kr'] &,
            html[site='za'] & {
                width: 70px;
                .score {
                    width: max-content;
                    font-size: 10px;
                    top: 50%;
                }
            }
            .score {
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 13px;
                font-family: var(--ff-woff2-B);
                html[site='uk'] &,
                html[site='de'] &,
                html[site='fr'] &,
                html[site='es'] &,
                html[site='it'] & {
                    width: max-content;
                }
            }
        }
        .WinC-a-title {
            font-family: var(--ff-woff2-M);
            font-size: 12px;
            color: #000;
            margin-top: 12px;
            text-align: center;
        }

        .WinC-b-list {
            display: flex;
            flex-direction: column;
            gap: 22px;
            margin-top: 22px;
        }
        .WinC-b-item {
            display: flex;
            gap: 12px;
        }
        .WinC-b-item-r {
            flex: 1;
        }
        .WinC-b-title {
            font-family: var(--ff-woff2-B);
            font-size: 12px;
            color: #000;
        }
        .WinC-b-score {
            font-family: var(--ff-woff2-M);
            font-size: 12px;
            color: #3f68e0;
            margin-top: 4px;
        }
    }
}

// 子弹窗 D
.WinD {
    :global {
        position: relative;
        .WinD-sub-title {
            margin: 10px 0 4px;
            font-family: var(--ff-woff2-B);
            font-size: 18px;
            color: #000;
            text-align: center;
            html[site='uk'] &,
            html[site='it'] &,
            html[site='es'] &,
            html[site='de'] &,
            html[site='za'] &,
            html[site='au'] &,
            html[site='eu'] & {
                margin: 10px 30px 4px;
            }
        }
        .WinD-item {
            display: flex;
            gap: 12px;
            padding: 14px 0;
            margin: 0 30px;
            position: relative;
            align-items: center;
        }
        .WinD-item-l {
            width: 32px;
            height: 32px;
            display: flex;
        }
        .WinD-item-r {
            font-family: var(--ff-woff2-M);
            font-size: 12px;
            color: #000;
            white-space: pre-line;
        }
        .WinD-email {
            margin: 15px 30px;
            .ef-emarsys-email-in {
                height: 40px;
                line-height: 40px;
                .ef-emarsys-email-input {
                    border: 1px solid #000;
                }
                .ef-emarsys-email-r {
                    background-color: #000;
                    padding: 0 20px;
                }
                @include devices(h5) {
                    height: auto;
                }
            }
        }
    }
}

// 子弹窗 E
.WinE {
    :global {
        position: relative;
        .WinE-item {
            display: flex;
            gap: 15px;
            padding: 20px 0;
            margin: 0 30px;
            position: relative;
            border-bottom: 1px solid #cacac8;
        }
        .WinE-item-l {
            width: 32px;
            height: 32px;
            display: flex;
        }
        .WinE-item-r {
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            color: #000;
            white-space: pre-line;
        }
        .winE-tips {
            margin: 30px 30px 0;
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            color: #000;
            white-space: pre-line;
        }
        .winE-btn {
            display: block;
            width: max-content;
            margin: 30px auto 0;
            padding: 7px 22px;
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            color: #fff;
            background-color: #3f68e0;
            border-radius: 4px;
            overflow: hidden;
            &:hover {
                opacity: 0.85;
            }
        }
    }
}

// 子弹窗 F
.WinF {
    :global {
        position: relative;
        .WinF-desc {
            padding: 30px;
            font-family: var(--ff-woff2-B);
            font-size: 16px;
            line-height: 1.2;
            color: #000;
            white-space: pre-line;
        }
        .WinF-text {
            padding-left: 30px;
            font-family: var(--ff-woff2-B);
            font-size: 16px;
            line-height: 1.2;
            color: #000;
            white-space: pre-line;
        }
        .WinF-item {
            display: flex;
            gap: 15px;
            padding: 20px 0;
            margin: 0 30px;
            position: relative;
            border-bottom: 1px solid #cacac8;
        }
        .WinF-item-l {
            width: 32px;
            height: 32px;
            display: flex;
        }
        .WinF-item-r {
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            line-height: 33px;
            color: #000;
            white-space: pre-line;
        }
    }
}

// 子弹窗 G
.WinG {
    :global {
        position: relative;
        .WinG-item {
            display: flex;
            gap: 15px;
            padding: 20px 0;
            margin: 0 30px;
            position: relative;
            border-bottom: 1px solid #cacac8;
        }
        .WinG-item-l {
            width: 32px;
            height: 32px;
            display: flex;
        }
        .WinG-item-r {
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            line-height: 32px;
            color: #000;
            white-space: pre-line;
        }
        .winG-btn {
            display: block;
            width: max-content;
            margin: 30px auto 0;
            padding: 7px 22px;
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            color: #fff;
            background-color: #3f68e0;
            border-radius: 4px;
            overflow: hidden;
            &:hover {
                opacity: 0.85;
            }
        }
    }
}
